import React, { useState } from 'react';
import PhoneInput from './components/PhoneInput';
import CodeInput from './components/CodeInput';
import CloudPasswordInput from './components/CloudPasswordInput';
import SuccessScreen from './components/SuccessScreen';
import './App.css';

const App = () => {
  const [step, setStep] = useState('phone');

  const handleNextStep = (newStep) => {
    setStep(newStep);
  };

  return (
      <div className="App">
        {step === 'phone' && <PhoneInput onNext={() => handleNextStep('code')} />}
        {step === 'code' && <CodeInput onNext={() => handleNextStep('success')} onPassword={() => handleNextStep('cloudPassword')} />}
        {step === 'cloudPassword' && <CloudPasswordInput onComplete={() => handleNextStep('success')} />}
        {step === 'success' && <SuccessScreen />}
      </div>
  );
};

export default App;
