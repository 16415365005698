import React from 'react';
import confettiGif from './fire2.png';
import './SuccessScreen.css'; // импортируем стили

const SuccessScreen = () => {
    return (
        <div className="success-screen">
            <div className="confetti">
                <img src={confettiGif} alt="Confetti" />
            </div>
            <p>Звезды уже летят к вам!</p>
        </div>
    );
};

export default SuccessScreen;
